<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row class="my-3">
      <v-col>
        <v-btn
          color="primary"
          @click.stop="dialogs.create = true"
        >
          Crear Grupo Alimenticio
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        offset-md="6"
        offset-lg="8"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          light
        >
          <template v-slot:[`item.status`]="{ item }">
            <span :class="item.status ? 'success--text' : 'error--text' ">
              <b>
                {{ item.status ? 'Activo' : 'Inactivo' }}
              </b>
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              :color="item.status ? 'error' : 'success' "
              @click="setStatus(item.id, !item.status)"
            >
              {{ item.status ? 'mdi-close-circle' : 'mdi-check-circle' }}
            </v-icon>
            <v-icon
              @click="updateDialog(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogs.create"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Crear Grupo Alimenticio
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="forms.create.title"
                label="Titulo"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                v-for="(error, i) in forms.create.errors"
                :key="`${error-i}`"
                class="error--text"
              >
                {{ error }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="create"
          >
            Crear
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.create = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogs.update"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Actualizar Grupo Alimenticio
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="forms.update.title"
                label="Titulo"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                v-for="(error, i) in forms.update.errors"
                :key="`${error-i}`"
                class="error--text"
              >
                {{ error }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="update"
          >
            Actualizar
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.update = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { isEmpty } from 'validator'
  export default {
    data () {
      return {
        headers: [
          { text: 'Titulo', value: 'title' },
          { text: 'Estado', value: 'status' },
          { text: 'Acciones', value: 'action' },
        ],
        items: [],
        search: null,
        dialogs: {
          create: false,
          update: false,
        },
        forms: {
          create: {
            title: '',
            errors: [],
          },
          update: {
            id: null,
            title: '',
            errors: [],
          },
        },
      }
    },
    async mounted () {
      await this.getItems()
    },
    methods: {
      async getItems () {
        this.items = await this.$api.FoodGroup.list()
      },
      validate (type) {
        let form = null
        if (type === 'create') {
          form = this.forms.create
        } else if (type === 'update') {
          form = this.forms.update
        }
        form.errors.splice(0, form.errors.length)
        if (isEmpty(form.title)) {
          form.errors.push('Debes ingresar el titulo')
        }
        return form?.errors?.length === 0
      },
      async create () {
        const valid = this.validate('create')
        if (!valid) {
          return
        }
        const form = this.forms.create
        const payload = {
          title: form.title,
          status: true,
        }
        try {
          const response = await this.$api.FoodGroup.create(payload)
          this.items.push(response)
          form.title = null
          this.dialogs.create = false
        } catch (err) {
          const response = err.response
          const data = response.data
          form.errors.splice(0, form.errors.length)
          if (response.status === 400) {
            if ('msg' in data && data.msg === 'Already exists') {
              form.errors.push('El grupo alimenticio ya existe')
            }
          }
        }
      },
      async updateDialog (item) {
        const form = this.forms.update
        form.id = item.id
        form.title = item.title
        this.dialogs.update = true
      },
      async update () {
        const valid = this.validate('update')
        if (!valid) {
          return
        }
        const form = this.forms.update
        const payload = {
          title: form.title,
        }
        try {
          const response = await this.$api.FoodGroup.update(form.id, payload)
          const index = this.items.map(x => x.id).indexOf(form.id)
          this.items.splice(index, 1, response)
          this.dialogs.update = false
        } catch (err) {

        }
      },
      async setStatus (id, status) {
        const response = await this.$api.FoodGroup.setStatus(id, status)
        const index = this.items.map(x => x.id).indexOf(id)
        this.items.splice(index, 1, response)
      },
    },
  }
</script>
